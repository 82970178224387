.heroStyling {
    color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 65vh;
}

.heroStylingLush {
    color: white;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}